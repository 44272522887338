@tailwind base;
@tailwind components;
@tailwind utilities;

.spin {
  animation-name: spin;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 8px;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0 0 0 30%);
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(203, 213, 225);
  outline: 2 solid slategrey;
    border-radius: 8px;

}